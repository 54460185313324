import { Spin } from 'antd'
import { useLoaderContext } from 'src/context/LoaderContext/LoaderContextProvider'
import styles from './loader.module.css'

const transitionStyles = {
    loaded: { opacity: 1 },
    closed: { opacity: 0 },
}

const Loader = () => {
    const { isLoading } = useLoaderContext()

    return (
        <div
            className={styles.loader}
            style={{
                display: isLoading ? 'flex' : 'none',
                transition: `opacity 300ms ease-in-out`,
                ...transitionStyles[isLoading ? 'loaded' : 'closed'],
            }}
        >
            <Spin size="large" />
        </div>
    )
}

export default Loader
