import '../utils/dd-rum'
/** dd-rum needs to be imported before anything else to properly hook all app modules */
import { ConfigProvider } from 'antd'
import { SessionProvider } from 'next-auth/react'
import { AppProps } from 'next/dist/shared/lib/router/router'
import { useRouter } from 'next/router'

import { FooterProvider } from 'src/components/common/FooterBar/FooterContext'
import TopNavBar from 'src/components/common/TopNavBar/TopNavBar'
import { appRoutesWithoutTopNavBar } from 'src/constants/appRoutes'
import LoaderContextProvider from 'src/context/LoaderContext/LoaderContextProvider'
import '../src/global.css'
import { poppins, theme } from '../src/helpers/themeHelper'
import '../src/index.css'

export default function MyApp({ Component, session, pageProps }: AppProps) {
    const router = useRouter()
    const topNavBarVisible = !Object.values(appRoutesWithoutTopNavBar)
        .map((x) => x.path)
        .includes(router.pathname)

    return (
        <SessionProvider session={session} refetchInterval={5 * 60}>
            {/* Defines global styling for Ant Design elements */}
            <ConfigProvider theme={theme}>
                <LoaderContextProvider>
                    {/* Defines global styling for non-Ant Design elements */}
                    <div className={poppins.className}>
                        <FooterProvider>
                            {topNavBarVisible && <TopNavBar />}
                            <main>
                                <Component {...pageProps} />
                            </main>
                        </FooterProvider>
                    </div>
                </LoaderContextProvider>
            </ConfigProvider>
        </SessionProvider>
    )
}
