import { Button, Menu, MenuProps } from 'antd'
import { signIn, signOut, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useId, useMemo } from 'react'
import { appRoutes } from 'src/constants/appRoutes'
import config from '../../../config/config'
import SubMenu from './SubMenu'
import styles from './TopNavBar.module.css'

const accessTokenKey = 'accessToken'
const refreshAccessTokenError = 'RefreshAccessTokenError'

const TopNavBar = () => {
    const { data: session, status } = useSession()
    const loading = status === 'loading'
    const id = useId()
    const router = useRouter()

    useEffect(() => {
        if (session === undefined) {
            return
        }

        if (session === null) {
            localStorage.removeItem(accessTokenKey)
            handleSignIn()
            return
        }

        localStorage.setItem(accessTokenKey, session.user.accessToken)
    }, [session])

    const handleSignIn = async () => {
        await signIn()
    }

    const handleSignOut = async () => {
        await signOut()
    }

    const menuItems: MenuProps['items'] = useMemo(() => {
        const items: MenuProps['items'] = []
        if (config.FF_MENU_SUBMENU_ENABLED) {
            items.push({
                label: <Link href={appRoutes.contracts.path}>Contracts</Link>,
                key: appRoutes.contracts.key,
            })
        } else {
            items.push(
                {
                    label: <Link href={appRoutes.templates.path}>Templates</Link>,
                    key: appRoutes.templates.key,
                },
                {
                    label: <Link href={appRoutes.contracts.path}>Contracts</Link>,
                    key: appRoutes.contracts.key,
                }
            )
        }

        if (config.FF_MENU_PLACEHOLDERS_ENABLED) {
            items.push({
                label: <Link href={appRoutes.placeholders.path}>Placeholders</Link>,
                key: appRoutes.placeholders.key,
            })
            items.push({
                label: <Link href={appRoutes.presentationTemplates.path}>Presentations</Link>,
                key: appRoutes.presentations.key,
            })
        }

        return items
    }, [])

    const getSelectedRouteForMainNav = () => {
        //contracts page does not follow a parent path
        if (router.pathname === appRoutes.templates.path || router.pathname === appRoutes.contracts.path) {
            return [appRoutes.contracts.key]
        }

        const parentRoutes = router.pathname.split('/')
        return parentRoutes.length > 1 ? [parentRoutes[1]] : []
    }

    const getSelectedRouteSubNav = () => {
        let route: keyof typeof appRoutes
        for (route in appRoutes) {
            if (appRoutes[route].path === router.pathname) {
                return [appRoutes[route].key]
            }
        }
        return []
    }

    if (session?.error === refreshAccessTokenError) {
        handleSignOut()
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <header className={styles.headerMenuContainer}>
            <div className={styles.headerMainMenuContainer}>
                <div className={styles['logo-wrapper']}>
                    <Image src="/enpal-icon-dark.png" alt="logo" height={35} width={35} />
                </div>
                <span className={styles['header-title']}>DocHub</span>
                {session ? (
                    <>
                        <Menu
                            key={id}
                            theme="dark"
                            mode="horizontal"
                            className={styles['menuItems']}
                            selectedKeys={getSelectedRouteForMainNav()}
                            items={menuItems}
                        ></Menu>

                        <span className={styles['header-user']}>{session.user.email}</span>
                        <Button className={styles['button']} type="primary" onClick={handleSignOut}>
                            Log Out
                        </Button>
                    </>
                ) : (
                    <>
                        <Menu key={id} theme="light" mode="horizontal" className={styles['menuItems']}></Menu>
                        <Button className={styles['button']} type="primary" onClick={handleSignIn}>
                            Log In
                        </Button>
                    </>
                )}
            </div>
            {config.FF_MENU_SUBMENU_ENABLED ? (
                <div className={styles.headerSubMenuContainer}>
                    {session && <SubMenu selectedKeys={getSelectedRouteSubNav()} />}
                </div>
            ) : (
                <></>
            )}
        </header>
    )
}

export { TopNavBar as default }
