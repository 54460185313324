import { datadogRum } from '@datadog/browser-rum'

const DEVELOPMENT = 'dev-local'

const getAppEnvFromUrl = () => {
    if (typeof window != 'undefined') {
        const { host } = window.location
        if (host.includes('dev')) {
            return 'development'
        }
        if (host.includes('stg')) {
            return 'staging'
        }
        if (host.includes('deploy')) {
            return 'deploy'
        }
        if (host === 'doctemplate.enpal.de') {
            return 'production'
        }
        if (host.includes('localhost')) {
            return DEVELOPMENT
        }
    } else return globalThis.process.env.APP_ENV
}

if (getAppEnvFromUrl() !== DEVELOPMENT) {
    datadogRum.init({
        applicationId: '10ae5b61-650c-44f3-8fd5-5b89fdb7eb60',
        clientToken: 'puba7a83daafc21a9d960bee1fa59c1844a',
        site: 'datadoghq.eu',
        service: 'doctemplate-frontend',
        env: getAppEnvFromUrl(),
        version: globalThis.process.env.appVersion,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingUrls: ['https://doctemplate.enpal.de', /https:\/\/sigx(-stg|-dev)?\.azurewebsites\.net/],
    })
}
