import { createContext, useContext, type PropsWithChildren } from 'react'
import Loader from 'src/components/layout/Loader/Loader'
import { useLoaderReducer } from './LoaderContext.reducer'
import { LoaderContext } from './LoaderContext.types'

const TheLoaderContext = createContext<LoaderContext>({} as LoaderContext)

const LoaderContextProvider = ({ children }: PropsWithChildren) => {
    const { state, loadTheLoader, closeTheLoader } = useLoaderReducer()

    const value: LoaderContext = {
        isLoading: state.isLoading,
        loadTheLoader,
        closeTheLoader,
    }

    return (
        <TheLoaderContext.Provider value={value}>
            <Loader />
            {children}
        </TheLoaderContext.Provider>
    )
}

export default LoaderContextProvider

export const useLoaderContext = () => useContext<LoaderContext>(TheLoaderContext)
