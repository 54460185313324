import { useReducer } from 'react'
import { LoaderState } from './LoaderContext.types'

const actions = {
    loadTheLoader: 'LoaderContext/loadTheLoader',
    closeTheLoader: 'LoaderContext/closeTheLoader',
}

type LoaderAction = {
    type: (typeof actions)[keyof typeof actions]
}

const initialState: LoaderState = {
    isLoading: false,
}

const loaderReducer = (state: LoaderState, action: LoaderAction): LoaderState => {
    switch (action.type) {
        case actions.loadTheLoader:
            return {
                ...state,
                isLoading: true,
            }
        case actions.closeTheLoader:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state
    }
}

export const useLoaderReducer = () => {
    const [state, dispatch] = useReducer(loaderReducer, initialState)

    const loadTheLoader = () => dispatch({ type: actions.loadTheLoader })
    const closeTheLoader = () => dispatch({ type: actions.closeTheLoader })

    return { state, loadTheLoader, closeTheLoader }
}
