import React, { createContext, ReactNode, useContext, useState } from 'react'
import styles from './footer.module.css'

interface FooterContextType {
    setFooterTemplate: (template: ReactNode) => void
}

const FooterContext = createContext<FooterContextType | undefined>(undefined)

export const FooterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [template, setFooterTemplate] = useState<ReactNode>(null)

    return (
        <FooterContext.Provider value={{ setFooterTemplate }}>
            {/* header/ children components */}
            <div style={{ paddingBottom: template ? '90px' : '0' }}>{children}</div>

            {/* Render the FooterBar */}
            <FooterBar template={template} />
        </FooterContext.Provider>
    )
}

export const useFooter = () => {
    const context = useContext(FooterContext)
    if (!context) {
        throw new Error('useFooter must be used within a FooterProvider')
    }
    return context
}

interface FooterProps {
    template?: React.ReactNode
}

const FooterBar: React.FC<FooterProps> = ({ template }) => {
    return (
        <>
            {template && (
                <div className={styles.footerContainer}>
                    <div className={styles.footer}>{template}</div>
                </div>
            )}
        </>
    )
}
