import { ThemeConfig } from 'antd'
import { Poppins } from 'next/font/google'

export const poppins = Poppins({
    weight: ['400', '700'],
    subsets: ['latin'],
})

// This theme defines changes to the Ant Design design theme.
// We can't access CSS variables here, so make sure you also update any relevant variables in /src/global.css

// VARIABLES
const backgroundColor = 'none'
const primary = '#415ff0'
const saveButtonBratGreen = '#BADA55'
const borderRadiusRound = 40

// THEME
export const theme: ThemeConfig = {
    token: {
        fontFamily: poppins.style.fontFamily,
    },
    components: {
        Button: {
            borderRadius: borderRadiusRound,
            borderRadiusSM: borderRadiusRound,
            borderRadiusLG: borderRadiusRound,
            colorPrimary: primary,
            colorBgContainer: saveButtonBratGreen,
        },
        Layout: {
            colorBgBody: backgroundColor,
        },
    },
}
