import { useRouter } from 'next/router'
import config from 'src/config/config'
import { appRoutes } from 'src/constants/appRoutes'
import SubMenuItems from './SubMenuItems'
import { SubMenuType, SubRoute } from './SubMenuItems.types'

const subRoutes: SubRoute[] = [
    {
        pathname: appRoutes.placeholders.path,
        subpaths: [
            { href: appRoutes.textPlaceholders.path, displayLabel: 'Text', key: appRoutes.textPlaceholders.key },
            { href: appRoutes.imagePlaceholders.path, displayLabel: 'Image', key: appRoutes.imagePlaceholders.key },
            {
                href: appRoutes.lineItemPlaceholders.path,
                displayLabel: 'LineItem',
                key: appRoutes.lineItemPlaceholders.key,
            },
            ...(config.FF_MENU_CONDITIONS_SUBMENU_ENABLED
                ? [
                      {
                          href: appRoutes.conditions.path,
                          displayLabel: 'Conditions',
                          key: appRoutes.conditions.key,
                      },
                  ]
                : []),
        ],
    },
    {
        pathname: appRoutes.templates.path,
        subpaths: [
            { href: appRoutes.templates.path, displayLabel: 'Contract templates', key: appRoutes.templates.key },
            { href: appRoutes.contracts.path, displayLabel: 'Contract groups', key: appRoutes.contracts.key },
        ],
    },
    {
        pathname: appRoutes.contracts.path,
        subpaths: [
            { href: appRoutes.templates.path, displayLabel: 'Contract templates', key: appRoutes.templates.key },
            { href: appRoutes.contracts.path, displayLabel: 'Contract groups', key: appRoutes.contracts.key },
        ],
    },
    {
        pathname: appRoutes.presentations.path,
        subpaths: [
            {
                href: appRoutes.presentationTemplates.path,
                displayLabel: 'Presentation templates',
                key: appRoutes.presentationTemplates.key,
            },
            {
                href: appRoutes.presentationGroups.path,
                displayLabel: 'Presentation groups',
                key: appRoutes.presentationGroups.key,
            },
        ],
    },
]

const SubMenu = ({ selectedKeys }: SubMenuType) => {
    const router = useRouter()
    const { asPath: routerPathName } = router
    const subRoute = subRoutes.find((subroute) => routerPathName.startsWith(subroute.pathname))

    if (!subRoute) {
        return null
    }

    return <SubMenuItems pathname={subRoute.pathname} menuItems={subRoute.subpaths} selectedKeys={selectedKeys} />
}

export default SubMenu
