import { Menu, MenuProps } from 'antd'
import Link from 'next/link'
import { memo, useId, useMemo } from 'react'
import styles from 'src/components/common/TopNavBar/TopNavBar.module.css'
import { SubMenuItemsProps } from './SubMenuItems.types'

const SubMenuItems = memo((props: Partial<SubMenuItemsProps>) => {
    const { pathname, menuItems, selectedKeys } = props
    const id = useId()

    const items: MenuProps['items'] = useMemo(
        () =>
            menuItems?.map((menuItem, index) => ({
                label: (
                    <Link key={index} href={menuItem.href}>
                        {menuItem.displayLabel}
                    </Link>
                ),
                key: menuItem.key,
            })),
        [menuItems]
    )

    if (pathname === undefined || pathname === '') {
        return null
    }

    return (
        <Menu
            key={id}
            theme="dark"
            mode="horizontal"
            selectedKeys={selectedKeys}
            className={styles['subMenuItems']}
            items={items}
        ></Menu>
    )
})

export default SubMenuItems
