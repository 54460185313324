export default {
    DOCTEMPLATE_API_URL: globalThis.process.env.ENPAL_PUBLIC_DOCTEMPLATE_API_URL,
    DOCTEMPLATE_API_AUTH_TOKEN: globalThis.process.env.ENPAL_PUBLIC_DOCTEMPLATE_API_AUTH_TOKEN,
    DOCTEMPLATE_API_SUBSCRIPTION_KEY: globalThis.process.env.ENPAL_PUBLIC_DOCTEMPLATE_API_SUBSCRIPTION_KEY,
    DOCTEMPLATE_API_SUBSCRIPTION_VALUE: globalThis.process.env.ENPAL_PUBLIC_DOCTEMPLATE_API_SUBSCRIPTION_VALUE,
    DOCVARIABLE_API_SUBSCRIPTION_KEY: globalThis.process.env.ENPAL_PUBLIC_DOCVARIABLE_API_SUBSCRIPTION_KEY,
    DOCVARIABLE_API_SUBSCRIPTION_VALUE: globalThis.process.env.ENPAL_PUBLIC_DOCVARIABLE_API_SUBSCRIPTION_VALUE,
    DOCVARIABLE_API_URL: globalThis.process.env.ENPAL_PUBLIC_DOCVARIABLE_API_URL,
    FF_MENU_PLACEHOLDERS_ENABLED:
        'true' === globalThis.process.env.ENPAL_PUBLIC_FF_MENU_PLACEHOLDERS_ENABLED?.toLowerCase(),
    FF_MENU_SUBMENU_ENABLED: 'true' === globalThis.process.env.ENPAL_PUBLIC_FF_MENU_SUBMENU_ENABLED?.toLowerCase(),
    FF_MENU_CONDITIONS_SUBMENU_ENABLED:
        'true' === globalThis.process.env.ENPAL_PUBLIC_FF_MENU_CONDITIONS_SUBMENU_ENABLED,
}
